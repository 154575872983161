import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Divider,
  Fade,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "../../styles/Home.module.scss";
import { ROUTES } from "../../routes";
import { useEffect, useState } from "react";
import { GetCurrentUserDetails } from "../../firebase/functions/user";
import { setUserInfo } from "../../store/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import pick from "../../images/combined-Shape.png";
import { ReactComponent as Notification } from "../../images/notification.svg";
import { ReactComponent as LocationIcon } from "../../images/location-icon.svg";
import { ReactComponent as SearchIcon } from "../../images/search.svg";
import { ReactComponent as FilterIcon } from "../../images/filter.svg";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

import { AiOutlinePlus } from "react-icons/ai";
import LocationHook from "../../hooks/useLocationHook";
import { setLoaded } from "../../store/LocationSlice";
import { useModalsContext } from "../../modals/ModalsContext";
import { MODALS } from "../../modals/modals";
import Artwork from "../../icons/Artwork.png";
import { useContext } from "react";
import { Context } from "../../context/StateContext";
import NotificationsContainer from "../Notifications/NotificationsContainer";

import {
  SortEventWithCityByUpcoming,
  SortEventWithLocationByUpcoming,
} from "../../firebase/functions/event/sort-event";
import {
  getAllNotifications,
  returnJoinedNotifications,
} from "../../firebase/functions/messaging/notifications";
import { clearEvent } from "../../store/EventSlice";
import GoogleAutocomplete from "../GoogleAutocomplete";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#F56C6C",
    color: "#F56C6C",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const HomeHeader = ({ handleDrawerOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.UserReducer.userInfo);
  const location = useSelector((state) => state.LocationReducer.location);
  const loaded = useSelector((state) => state.LocationReducer.loaded);
  const isOpen = useSelector((state) => state.OpenMenuReducer.isOpen);

  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userPhoto, setUserPhoto] = useState("");

  const [notificationModal, setNotificationModal] = useState(false);

  const [search, setSearch] = useState("");

  const notificationModalOpen = () => setNotificationModal(!notificationModal);
  const notificationModalClose = () => setNotificationModal(false);

  const { setCurrentLocationPoints , getLocation} = LocationHook();
  const { setOpenModal } = useModalsContext();
  const locationEvents = useSelector((state) => state.LocationReducer.location);
  const { notifications, setNotifications } = useContext(Context);
  const { eventData, setEventData } = useContext(Context);
  const [shouldReset, setShouldReset] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);

  useEffect(() => {
    getAllNotifications().then(async () => {
      const notifyData = await returnJoinedNotifications();
      setNotifications(notifyData);
    });
  }, []);

  useEffect(() => {
    if (userInfo !== null) {
      setUserPhoto(userInfo?.photoURL);
      setUserName(userInfo?.displayName);
      if (userInfo?.displayName.split(" ").length >= 1) {
        setFirstName(userInfo?.displayName.split(" ")[0]);
        setLastName(userInfo?.displayName.split(" ")[1]);
        setUserPhoto(userInfo?.photoURL);
      } else {
        setFirstName(userInfo?.displayName);
      }
    }
  }, [userInfo]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter the eventData based on the search term
    const filteredEvents = eventData.filter((event) =>
      event.title.toLowerCase().includes(searchTerm)
    );

    setSearch(searchTerm);
    setEventData(filteredEvents);

    if (e.target.value === "") {
      if (locationEvents.structured_formatting.main_text !== "") {
        SortEventWithCityByUpcoming(
          locationEvents.structured_formatting.main_text,
          4
        ).then((events) => {
          setEventData(events);
        });
      } else {
        SortEventWithLocationByUpcoming(4).then((events) => {
          setEventData(events);
        });
      }
    }
  };
  const createNewEvent = () => {
    dispatch(clearEvent());
    navigate(ROUTES.CREATE_EVENT);
  };

  const HandleLocationChange = async (event) => {
    const city = event.structured_formatting.main_text;
    // onData(city);
  };

  return (
    <>
      <Container
        className="header-input"
        sx={{
          // pt: { xs: 1, md: 2 },
          paddingTop: isOpen ? "33px" : "8px",
          backgroundColor: "#20ACAC",
          borderRadius: isOpen ? "33px" : "0px 0px 33px 33px",
          display: { xs: "block", sm: "none" },
        }}
        onClick={() => {
          setOpenLocation(false);
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <img src={pick} alt="user" onClick={handleDrawerOpen} />
          <Box
            style={{ marginLeft: "6px" }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenLocation(!openLocation);
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                mb: openLocation ? "20px" : 0,
                color: "#FEFEFE",
                fontWeight: "400",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              variant="subtitle1"
            >
              Current Location
              <span style={{ marginLeft: "3px" }}>
                <LocationIcon />
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                mb: 0,
                color: "#FFFFFF",
                fontWeight: "400",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              variant="subtitle2"
              gutterBottom
            >
              {!openLocation &&
                location.structured_formatting.main_text +
                  ", " +
                  location.structured_formatting.secondary_text}
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={notificationModalOpen}
              // onClick={() => navigate(ROUTES.NOTIFICATIONS)}
              sx={{
                bgcolor: "#35b3b3",
                color: "info.main",
                fontSize: { xs: 24, md: 30 },
                borderRadius: "50%",
                width: { xs: "42px", sm: "60px" },
                height: { xs: "42px", sm: "60px" },
                minWidth: "unset",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                boxShadow: 0,
                p: 0,
              }}
              variant="contained"
            >
              {notificationModal && (
                <Modal
                  open={notificationModal}
                  onClose={notificationModalClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={notificationModal}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "33%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        height: "50%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 3,
                        borderRadius: "20px",
                      }}
                    >
                      {notifications.length > 0 ? (
                        <Box style={{ maxHeight: "300px", overflowY: "auto" }}>
                          <NotificationsContainer style={{ p: "10px" }} />
                        </Box>
                      ) : (
                        <Stack
                          px={3}
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                          height="100%"
                        >
                          <Box sx={{ position: "relative" }}>
                            <IconButton fontSize="400" sx={{ fontSize: 300 }}>
                              <img src={Artwork} />
                            </IconButton>
                          </Box>

                          <Typography
                            variant="p"
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              fontSize: "18px",
                              marginTop: "-25px",
                            }}
                          >
                            You have no notifications!
                          </Typography>
                        </Stack>
                      )}
                    </Box>
                  </Fade>
                </Modal>
              )}

              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                variant="dot"
              >
                <Notification />
              </StyledBadge>
            </Button>
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
            marginBottom={2}
            gap={1}
            divider={
              <Divider
                sx={{ color: "#53DFDF", height: "25px", marginTop: "5px" }}
                orientation="vertical"
                flexItem
              />
            }
          >
            <Box>
              <SearchIcon />
            </Box>
            <TextField
              placeholder="Search..."
              variant="standard"
              value={search}
              onChange={handleSearchChange}
            />
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "50px",
              backgroundColor: "#27C9C9",
              padding: "5px 8px 5px 8px",
              fontSize: "12.3px",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => setOpenModal(MODALS.SORT_FILTER)}
          >
            <FilterIcon style={{ marginRight: "5px" }} />
            Filters
          </Box>
        </Stack>
      </Container>
      {openLocation && (
        <Stack
          sx={{
            position: "absolute",
            top: "4%",
            left: "25%",
            width: "50%",
            zIndex: "99",
            display: { xs: "block", sm: "none" },
          }}
        >
          <GoogleAutocomplete
            setOpenLocation={setOpenLocation}
            onChange={HandleLocationChange}
          />
        </Stack>
      )}
      <Box
        sx={{
          position: "fixed",
          right: "30px",
          bottom: "95px",
          zIndex: 999,
        }}
      >
        <Button
          onClick={createNewEvent}
          sx={{
            aspectRatio: "1/1",
            background: "#F56C6C",
            color: "info.main",
            fontSize: { xs: 35, md: 40 },
            borderRadius: "50%",
            width: { xs: "46px", sm: "65px" },
            height: { xs: "46px", sm: "65px" },
            minWidth: "unset",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            cursor: "pointer",
            boxShadow: " 0px 8px 20px 0px #4A43EC40",
            p: 0,
            ":hover": {
              background: "#ef5c5c",
            },
          }}
          variant="contained"
        >
          <AiOutlinePlus width={"24px"} height={"24px"} />
        </Button>
      </Box>
    </>
  );
};

export default HomeHeader;
