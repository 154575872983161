import { createSlice } from "@reduxjs/toolkit";

export const UserReducer = createSlice({
  name: "user",
  initialState: {
    userInfo: {
      search: [],
      token: "",
      uid: "",
      keywords: [],
      location: {
        lat: 10,
        lng: 10,
        city: "",
      },
      photoURL: "",
      isPhoneVerified: true,
      notificationControls: {
        new: true,
        join: true,
        reminder: true,
        update: true,
        left: true,
      },
      phone: "",
      groups: [],
      displayName: "Anonymous",
      email: "Anonymous",
      aboutUs: "",
      interest: [],
      folowing: 0,
      followers: 0,
      pastEvent: 0,
    },
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    clearInfoUser: (state) => {
      state.userInfo = {
        search: [],
        token: "",
        uid: "",
        keywords: [],
        location: {
          lat: 10,
          lng: 10,
          city: "",
        },
        photoURL: "",
        isPhoneVerified: true,
        notificationControls: {
          new: true,
          join: true,
          reminder: true,
          update: true,
          left: true,
        },
        phone: "",
        groups: [],
        displayName: "Anonymous",
        email: "Anonymous",
        aboutUs: "",
        interest: [],
        folowing: 0,
        followers: 0,
        pastEvent: 0,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo, clearInfoUser } = UserReducer.actions;

export default UserReducer.reducer;
