import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useCreateEventContext } from "../../pages/CreateEvent";
import { ReactComponent as InfoSvg } from "../../images/info.svg";
import { ReactComponent as CancelSvg } from "../../images/cross.svg";
import { ReactComponent as PopupInfoSvg } from "../../images/green-info.svg";
import { addMinutes } from "../../utils/timeFunctions";
import NextButton from "./NextButton";
import { State } from "country-state-city";
const DetailCard = ({ title, children }) => {
  return (
    <Stack
      width="100%"
      borderRadius="8px"
      bgcolor="white"
      sx={{
        border: "1px solid #D9D9D9",
        padding: "10px",
      }}
    >
      {children}
    </Stack>
  );
};

const Dropdown = ({ options, state, setState }) => {
  return (
    <select
      style={{
        fontSize: "16px",
        fontWeight: "400",
      }}
      className="state-dropdown placeHolder"
      defaultValue={state}
      placeholder={state}
      onChange={(e) =>
        setState((prev) => ({
          ...prev,
          state: e.target.value,
        }))
      }
      value={state}
      key={state}
    >
      <option key={"lkl"} value={""}>
        State
      </option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.displayValue}
        </option>
      ))}
    </select>
  );
};

const StateComponent = ({ countryCode = "US", state, setState }) => {
  const data = State.getStatesOfCountry(countryCode).map((states) => ({
    value: states.name,
    displayValue: `${states.isoCode}`,
  }));
  const currentState = State.getStatesOfCountry(countryCode).find((prev) => {
    return state === prev.isoCode;
  });
  return <Dropdown options={data} state={currentState?.name || state} setState={setState}></Dropdown>;
};

const Text = ({ title }) => {
  return (
    <Typography
      sx={{ fontSize: "14px", fontWeight: 400, fontFamily: "PoppinsRegular" }}
    >
      <span style={{ color: "#F56C6C", marginRight: "5px" }}>*</span>
      {title}
    </Typography>
  );
};

const FormPage = ({ setPages, pages }) => {
  const {
    title,
    date,
    startTime,
    cost,
    startAddress,
    apartment,
    city,
    state,
    setState,
    privateAddress,
  } = useCreateEventContext();

  const [_customPrice, setCustomPrice] = useState("");
  const [openPopup, setOpenPopup] = useState(false);

  const privatePopup = (e) => {
    e.stopPropagation();
    setOpenPopup(!openPopup);
  };

  const canclePopup = () => {
    setOpenPopup(false);
  };

  const NextPage = () => {
    setPages(pages + 1);
  };

  return (
    <Stack gap={2} width="100%" onClick={canclePopup}>
      <Stack gap={2}>
        <Text title="First up, what should we call this?" />
        <DetailCard title="Event Name">
          <input
            className="placeHolder input-style"
            type="text"
            placeholder="Event Name"
            value={title}
            onChange={(e) =>
              setState((prev) => ({ ...prev, title: e.target.value }))
            }
          />
        </DetailCard>
        <Text title="When is this happening?" />
        <Stack gap={2} flexDirection="row" sx={{ marginBottom: "10px" }}>
          <DetailCard title="Date">
            <input
              value={date}
              className="placeHolder date input-style"
              onChange={(e) =>
                setState((prev) => ({ ...prev, date: e.target.value }))
              }
              type="date"
              placeholder="Select date"
              style={{
                width: "min-content",
              }}
            />
          </DetailCard>
          <DetailCard title="Start Time">
            <input
              type="time"
              value={startTime}
              className="placeHolder input-style"
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  startTime: e.target.value,
                  endTime: addMinutes(e.target.value, 60),
                }))
              }
              step={900}
            />
          </DetailCard>
        </Stack>
        <Text title="Where will it take place?" />
        <p className="map-link">
          Enter the address of the event below, or{" "}
          <span
            style={{
              color: "#20ACAC",
              textDecorationLine: "underline",
              fontFamily: "PoppinsRegular",
            }}
            onClick={() => {
              setPages(10);
            }}
          >
            use our Pin Drop feature
          </span>{" "}
          to select the exact geo-location (
          <span style={{ fontStyle: "italic", fontFamily: "PoppinsRegular" }}>
            great for large outdoor areas where there are multiple places to
            meet
          </span>
          ).
        </p>
        <DetailCard title="Address">
          <input
            type="text"
            className="placeHolder input-style"
            placeholder="Event Address"
            value={startAddress}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                startAddress: e.target.value,
              }))
            }
          />
        </DetailCard>
        <DetailCard title="Address">
          <input
            type="text"
            className="placeHolder input-style"
            placeholder="Unit, Apt, etc."
            value={apartment}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                apartment: e.target.value,
              }))
            }
          />
        </DetailCard>
        <Stack gap={2} flexDirection="row">
          <DetailCard title="Address">
            <input
              type="text"
              className="placeHolder input-style"
              placeholder="City"
              value={city}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  city: e.target.value,
                }))
              }
            />
          </DetailCard>
          <DetailCard title="Address">
            <StateComponent state={state} setState={setState} />
          </DetailCard>
        </Stack>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            className="checkbox"
            checked={privateAddress}
            value={privateAddress}
            onChange={() => {
              setState((prev) => ({
                ...prev,
                privateAddress: !prev.privateAddress,
              }));
            }}
          />
          <label className="checkbox-label">
            {" "}
            This is a private address/event.
          </label>
          <span style={{ width: "14px", height: "15px" }}>
            <InfoSvg onClick={privatePopup} />
          </span>
        </div>
        {openPopup && (
          <div className="popup-div">
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <CancelSvg onClick={privatePopup} />
            </div>
            <div style={{ display: "flex" }}>
              <PopupInfoSvg />
              <p className="popup-paragraph">We take your privacy seriously.</p>
            </div>
            <div className="popup-text">
              Marking as a private address/ event will keep location details
              confidential. Only users you accept as attendees will be able to
              see the location.
            </div>
          </div>
        )}
        <div onClick={NextPage} style={{ width: "100%" }}>
          <NextButton />
        </div>
      </Stack>
    </Stack>
  );
};

export default FormPage;
