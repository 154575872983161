import { useLoadScript } from "@react-google-maps/api";
import Map from "./Map";
import { useState } from "react";

const MapView = ({
  markers,
  height,
  events,
  eventDetail,
  setEventCategory,
  eventCategory,
  view,
  setView,
  filters,
  tags,
  handleView,
  width,
}) => {
  const [center, setCenter] = useState({});
  if (events) markers = events;

  // const onUserLocationChange = (location) => {
  //     setUserLocation(location);
  // }

  return (
    <div>
      <Map center={center} filters={filters} events={markers} height={height} eventDetail={eventDetail} setEventCategory={setEventCategory} eventCategory={eventCategory}/>
    </div>
  );
};

export default MapView;
