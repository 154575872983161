import { Button, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initialState } from "../../pages/CreateEvent";
import { ROUTES } from "../../routes";
import { clearCheckboxes } from "../../store/EventSlice";
import { emptyTags } from "../../store/ModelSlice";
import OneTimeGif from "./gif";

const EventCreate = ({ setPages, setState, loader, eventId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const nextPage = () => {
    dispatch(clearCheckboxes());
    dispatch(emptyTags([]));
    setState(initialState);
    setPages(1);
  };

  const navigateToEvent = () => {
    setState(initialState);
    navigate(ROUTES.EVENT_DETAILS.replace(":id", eventId));
  };

  return (
    <Stack width="100%">
      <Stack>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 500,
            lineHeight: "36px",
            color: "#120D26",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          Event Created!
        </Typography>
        <div className="gif-div">
          <OneTimeGif loader={loader} />
        </div>
        <Stack sx={{ position: "absolute", bottom: "10%", width: "89vw" }}>
          <Stack direction="row" sx={{ marginY: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F56C6C",
                width: "100%",
                height: "38px",
                fontSize: "12px",
                fontWeight: 700,
                borderRadius: "7px",
                boxShadow: "0px 8px 20px 0px #4AD2E415",
              }}
              onClick={navigateToEvent}
            >
              GO TO EVENT PAGE
            </Button>
          </Stack>
          <Stack direction="row" sx={{ marginY: "10px" }} onClick={nextPage}>
            <Button
              variant="outlined"
              sx={{
                border: " 1px solid #F56C6C",
                width: "100%",
                height: "38px",
                fontSize: "12px",
                fontWeight: 700,
                borderRadius: "7px",
                boxShadow: "0px 8px 20px 0px #4AD2E415",
                color: "#F56C6C",
              }}
            >
              ADD ANOTHER EVENT
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventCreate;
