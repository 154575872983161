import { createSlice } from "@reduxjs/toolkit";

export const EventDataReducer = createSlice({
  name: "event",
  initialState: {
    eventType: "create",
    updateEventData: {},
    updateEventID: "",
    checkbox: [
      { title: "Sports & Games", isChecked: false },
      { title: "Adventure & Hiking", isChecked: false },
      { title: "Community Event", isChecked: false },
      { title: "Chill Hang", isChecked: false },
      { title: "Art & Music", isChecked: false },
    ],
  },
  reducers: {
    setUpdateEventData: (state, action) => {
      state.updateEventData = action.payload;
    },
    setEventType: (state, action) => {
      state.eventType = action.payload;
    },
    setUpdateEventID: (state, action) => {
      state.updateEventID = action.payload;
    },
    setCheckboxes: (state, action) => {
      state.checkbox = action.payload;
    },
    clearEvent: (state) => {
      state.updateEventID = "";
      state.updateEventData = {};
      state.eventType = "create";
    },
    clearCheckboxes: (state) => {
      state.checkbox = [
        { title: "Sports & Games", isChecked: false },
        { title: "Adventure & Hiking", isChecked: false },
        { title: "Community Event", isChecked: false },
        { title: "Chill Hang", isChecked: false },
        { title: "Art & Music", isChecked: false },
      ];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUpdateEventData,
  setEventType,
  setUpdateEventID,
  setCheckboxes,
  clearCheckboxes,
  clearEvent,
} = EventDataReducer.actions;

export default EventDataReducer.reducer;
