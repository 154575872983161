import React, { useState, useEffect } from "react";
import { Button, Container, Stack, Typography } from "@mui/material";
import PageHeader from "../components/PageHeader";
import FormPage from "../components/CreateEvent/MainDetails";
import ImagePage from "../components/CreateEvent/ImagePage";
import CategoryPage from "../components/CreateEvent/CategoryPage";
import PricePage from "../components/CreateEvent/PricePage";
import EventCreate from "../components/CreateEvent/EventCreated";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCheckboxes } from "../store/EventSlice";
import { ReactComponent as CancelSvg } from "../images/cross.svg";
import { ROUTES } from "../routes";
import { useModalsContext } from "../modals/ModalsContext";
import { emptyTags } from "../store/ModelSlice";
import MapPage from "../components/CreateEvent/mapPage";

const CreateEventContext = React.createContext();
export const useCreateEventContext = () => React.useContext(CreateEventContext);
export const initialState = {
  photos: [],
  title: "",
  description: "",
  category: [],
  date: "",
  startTime: "",
  keywords: [],
  maxParticipants: 0,
  cost: 0,
  eventType: "Outdoor",
  privateAddress: false,
  displayAddress: "",
  startAddress: "",
  apartment: "",
  city: "",
  state: "",
  address: null,
};

const CreateEvent = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateEventData = useSelector((state) => state.EventDataReducer.updateEventData);
  
  const eventType = useSelector((state) => state.EventDataReducer.eventType);

  const [pages, setPages] = useState(1);
  const [loader, setLoader] = useState(false);
  const [checkedValues, setCheckedValues] = useState("");
  const [eventId, setEventId] = useState("");

  const [state, setState] = useState(initialState);

  useEffect(() => {
    return () => {
      dispatch(clearCheckboxes());
      dispatch(emptyTags([]));
    };
  }, []);

  const backToHome = () => {
    navigate(ROUTES.HOME);
  };

  useEffect(() => {
    if (eventType === "update") {
      const dataFunction = new Date(updateEventData.date.toMillis());
      setState({
        ...updateEventData,
        date: dataFunction.toISOString().slice(0, 10),
      });
    }
  }, [eventType, updateEventData]);

  const NextPage = () => {
    setPages(pages - 1);
  };

  const pageComponents = [
    <FormPage setPages={setPages} pages={pages} />,
    <ImagePage
      State={setState}
      description={state.description}
      setPages={setPages}
      pages={pages}
    />,
    <CategoryPage
      setPages={setPages}
      pages={pages}
      setState={setState}
      state={state}
      checkedValues={checkedValues}
      setCheckedValues={setCheckedValues}
    />,
    <PricePage
      setPages={setPages}
      pages={pages}
      state={state}
      setLoader={setLoader}
      setEventId={setEventId}
    />,
    <EventCreate
      setPages={setPages}
      pages={pages}
      setState={setState}
      loader={loader}
      eventId={eventId}
    />,
  ];

  return (
    <CreateEventContext.Provider value={{ ...state, setState }}>
      <Container sx={{ my: 4, pb: 5, paddingX: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent:
              pages === 1 || pages === 5 || pages === 10
                ? "end"
                : "space-between",
            width: "100%",
          }}
        >
          {pages !== 1 && pages !== 5 && pages !== 10 && (
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400 }}
              variant="h3"
              onClick={NextPage}
            >
              BACK
            </Typography>
          )}
          <span onClick={backToHome}>
            <CancelSvg />
          </span>
        </div>
        {pages !== 5 && pages !== 10 && (
          <PageHeader
            pages={pages}
            my={1}
            title={eventType === "update" ? "Update Event" : "Create Event"}
          />
        )}
        <form>
          <Stack alignItems="center" gap={4}>
            {pageComponents[pages - 1]}

            {pages === 10 && (
              <MapPage
                setPages={setPages}
                pages={pages}
                setState={setState}
                loader={loader}
              />
            )}

            {pages !== 5 && pages !== 10 && (
              <div style={{ display: "flex" }}>
                {Array.from({ length: pageComponents.length }).map(
                  (_, index) => (
                    <div
                      className="round"
                      key={index}
                      style={{
                        backgroundColor: "#303030",
                        opacity: pages === index + 1 ? "100%" : "20%",
                      }}
                    ></div>
                  )
                )}
              </div>
            )}
          </Stack>
        </form>
      </Container>
    </CreateEventContext.Provider>
  );
};

export default CreateEvent;
